<template>
  <div>
    <!--  ====== 首行 ======  -->
    <topNav></topNav>
    <div style="height: 608px;padding-top: 22px;background-color: #F5F8FA;box-sizing: border-box">
      <div class="container">
        <div class="modify" v-if="this.steps == 1">
          <div class="modify-bu">
            <img src="../../assets/login/步骤01-01.png">
            <img src="../../assets/login/步骤01-02.png">
            <img src="../../assets/login/步骤01-03.png">

            <div class="modify-bu-zi">
              <div class="modify-bu-zi-zou2">
                <span class="modify-bu-zi-num">1</span>
                验证身份
              </div>

              <div class="modify-bu-zi-zou">
                <span>2</span>
                设置新密码
              </div>

              <div class="modify-bu-zi-zou">
                <span>3</span>
                完成
              </div>
            </div>
          </div>

          <div class="modify-content">
            <div class="modify-input" style="width: 100%;">
              <span style="text-align: left;">手机号</span>
              <el-input placeholder="请输入账号或手机号" v-model="changePhoneList.phone" clearable>
              </el-input>
            </div>
            <div class="modify-input" style="width: 100%;">
              <span style="text-align: left;">验证码</span>
              <div class="modify-ma">
                <el-input placeholder="请输入验证码" v-model="changePhoneList.code" clearable>
                </el-input>
                <el-button type="primary" style="margin-left: 10px" @click="openCodePage" :disabled="totalTime < 60">
                  {{ content }}
                </el-button>
              </div>
            </div>

            <el-button style="width: 130px;margin-top: 34px" type="primary" @click="changePhone()">下一步</el-button>
          </div>
        </div>

        <div class="modify" v-if="this.steps == 2">
          <div class="modify-bu">
            <img src="../../assets/login/步骤02-01.png" @click="changeStep(1)">
            <img src="../../assets/login/步骤02-02.png" @click="changeStep(2)">
            <img src="../../assets/login/步骤02-03.png" @click="changeStep(3)">

            <div class="modify-bu-zi">
              <div class="modify-bu-zi-zou">
                <span class="modify-bu-zi-num" style="background: rgba(9,112,213,0.1);">
                  <img style="width: 16px;height: 16px" src="../../assets/home/icon-Check.png">
                </span>
                验证身份
              </div>

              <div class="modify-bu-zi-zou2" @click="changeStep(2)">
                <span>2</span>
                设置新密码
              </div>

              <div class="modify-bu-zi-zou">
                <span>3</span>
                完成
              </div>
            </div>
          </div>

          <div class="modify-content">
            <div class="modify-input" style="width: 100%;">
              <span style="text-align: left;">新密码</span>
              <el-input placeholder="请输入新密码" v-model="pwd" show-password clearable>
              </el-input>
            </div>
            <div class="modify-input" style="width: 100%;text-align: left;">
              <span>确认密码</span>
              <div>
                <el-input placeholder="请再次输入新密码" v-model="pwd1" show-password clearable>
                </el-input>
              </div>
            </div>

            <el-button style="width: 130px;margin-top: 34px" type="primary" @click="changePwd()">确认</el-button>
          </div>
        </div>

        <div class="modify" v-if="this.steps == 3">
          <div class="modify-bu">
            <img src="../../assets/login/步骤03-01.png" @click="changeStep(1)">
            <img src="../../assets/login/步骤03-02.png" @click="changeStep(2)">
            <img src="../../assets/login/步骤03-03.png" @click="changeStep(3)">

            <div class="modify-bu-zi">
              <div class="modify-bu-zi-zou">
                <span class="modify-bu-zi-num" style="background: rgba(9,112,213,0.1);">
                  <img style="width: 16px;height: 16px" src="../../assets/home/icon-Check.png">
                </span>
                验证身份
              </div>

              <div class="modify-bu-zi-zou">
                <span class="modify-bu-zi-num" style="background: rgba(9,112,213,0.1);">
                  <img style="width: 16px;height: 16px" src="../../assets/home/icon-Check.png">
                </span>
                设置新密码
              </div>

              <div class="modify-bu-zi-zou2">
                <span>3</span>
                完成
              </div>
            </div>
          </div>

          <div class="modify-content-3">
            <img src="../../assets/login/Ellipse 34.png">
            <span class="modify-content-3-pasword">密码修改成功</span>
            <span> {{ countdown }} s后自动跳转</span>
          </div>
        </div>

      </div>
    </div>


    <div style="position: relative;bottom: 0">
      <!-- footer -->
      <div class="footer">
            <div class="footer-detail">
                <div style="width: 82.7%;">
                    <img class="code-logo" :src="$store.getters.enterpriseObj.config.homeLogo" alt="">
                    <div style="margin-top: 10px;margin-bottom: 10px;">
                        <span>主办单位：{{ $store.getters.enterpriseObj.name }}</span>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <span>地址： {{ $store.getters.enterpriseObj.address }}</span>
                    </div>
                    <div style="margin-bottom: 10px;">
                        <span>联系电话： {{ $store.getters.enterpriseObj.mobile }}</span>
                    </div>
                </div>
            </div>
        </div>

      <!-- bottom-->
      <div class="bottom">
        <div class="bottom-content">
          <div>Copyright 2023 • 苏州市电子信息技师学院</div>
          <div class="bottom-right">
            <a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">苏ICP备11034322号-2</a>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :before-close="closeCodePage" title="请输入验证码" :visible.sync="dialogVisible" width="434px"
      style="margin-top: 12%;">
      <div>
        <el-input placeholder="请输入验证码" clearable v-model="changePhoneList.captcha"
          style="width: 200px;text-align: center;"></el-input>
        <img style="float: right;" @click="getUuid" :src="codeUrl">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeCodePage()">取 消</el-button>
        <el-button type="primary" @click="getCountdown">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import topNav from "@/components/nav/nav.vue"
import { adornUrl, httpNPPost, httpNPGet, httpPost, httpGet } from "@/utils/httpRequest";

export default {
  name: "",
  data() {
    return {
      steps: 1,
      name: '',
      ma: '',
      countdown: '6',
      timer: null,
      totalTime: 60,
      content: "发送验证码",
      // 验证身份
      changePhoneList: {
        phone: '',
        code: '',
        captcha: '',
        uuid: '',
      },
      pwd: '',
      pwd1: '',
      changePhoneDate: [],
      userId: "",
      dialogVisible: false,
      codeUrl: '',
    };
  },
  components: {
    topNav
  },
  watch: {
    steps: {
      handler(newName, oldName) {
        // console.log('obj.lable changed' + this.steps);
        if (this.steps == 3) {
          this.sendCode()
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getUuid();
  },
  methods: {
    // 获取uuid
    getUuid() {
      httpNPGet("/captcha/uuid", (res) => {
        let uuid = res.data.uuid;
        this.changePhoneList.uuid = uuid;
        this.codeUrl = adornUrl("/captcha/img?uuid=" + uuid);
      }, (err) => {

      })
    },
    // 打开验证码弹窗
    openCodePage() {
      this.getUuid()
      if ((this.changePhoneList.phone + "").length == 11) {
        this.dialogVisible = true;
      } else {
        // console.log((this.changePhoneList));
        this.$message.error('请填写正确的手机号');
      }
    },
    // 关闭验证码弹窗
    closeCodePage() {
      this.dialogVisible = false;
      this.changePhoneList.captcha = ''
    },
    // 60s 倒计时
    getCountdown() {
      if ((this.changePhoneList.phone + "").length == 11) {
        this.getMa();

      } else {
        this.$message.error('请填写正确的手机号');
      }
    },

    // 验证码
    getMa() {
      httpPost("/captcha/send-update-pwd",
        {
          captcha: this.changePhoneList.captcha,
          phone: this.changePhoneList.phone,
          uuid: this.changePhoneList.uuid,
        },
        (res) => {
          let clock = window.setInterval(() => {
            this.content = this.totalTime + 's后重新发送';
            this.totalTime--;
            if (this.totalTime < 0) {
              this.totalTime = 60;
              this.content = "重新发送验证码";
              window.clearInterval(clock);
            }
          }, 1000);
          this.closeCodePage()
          // console.log(res)
        }, (err) => {
          this.$message.error(err.msg)
        })

    },
    //  -----------定时器-------------
    sendCode() {
      this.loading();  //启动定时器
      this.timer = setInterval(() => {
        //创建定时器
        if (this.countdown === 1) {
          this.clearTimer();  //关闭定时器
          this.$router.push('/login')
        } else {
          this.loading();
        }
      }, 1000);
    },
    loading() {
      //启动定时器
      this.countdown--;  //定时器减1
    },
    clearTimer() {
      //清除定时器
      clearInterval(this.timer);
      this.timer = null;
    },
    //  改变步骤
    changeStep(item) {
      this.steps = item
    },
    changePhone() {
      if ((this.changePhoneList.phone + "").length == 11) {
        if (this.changePhoneList.code != '') {
          this.steps = 2;
          // httpGet("/captcha/contrast",
          //   { ...this.changePhoneList }
          //   , (res) => {
          //     console.log(res)
          //     this.userId = res.data.userId
          //   }, (err) => {
          //     if (err.msg = "用户异常") {
          //       this.$message.error('该手机号未注册，请检查您的手机号!')
          //     }
          //     // console.log(err)
          //     throw err
          //   })
        } else {
          this.$message.error('请填写验证码');
        }
      } else {
        this.$message.error('请输入11位手机号');
      }
    },
    changePwd() {
      if (this.pwd.length > 7) {
        if (this.pwd == this.pwd1) {
            // 定义密码规则：至少一个小写字母、一个大写字母、一个数字和一个特殊字符，且长度至少为8
            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");

            if (!strongRegex.test(this.pwd)) {
                this.$message.error('密码强度不符合要求：至少一个小写字母、一个大写字母、一个数字和一个特殊字符，且长度至少为8');
                return;
            }
          httpPost("/app/app-user/find", {
            phone: this.changePhoneList.phone,
            code: this.changePhoneList.code,
            pwd: this.pwd,
          }, (res) => {
            // console.log('--密码修改成功--', res)
            this.steps = 3;
          }, (err) => {
            if (err.code == 500) {
              if (err.msg == "token异常，请重新登录") {
                localStorage.clear();
                if (!localStorage.getItem("userId")) {
                  this.$message.error('登录过期，请重新登录!')
                  this.$router.push("/login")
                }
              }
            }
            this.$message.error(err.msg)
            // // console.log(err)
            throw err
          })
        } else {
          this.$message.error('密码不同，请重新输入');
          this.pwd1 = '';
        }
      } else {
        this.$message.error('请输入8位以上密码');
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  width: 58px;
  height: 58px;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.header {
  height: 40px;
  line-height: 40px;
  background-color: #303030;
  color: aliceblue;
  font-size: 14px;

  .header-top {
    display: flex;
    justify-content: space-between;
    background-color: #303030;
    color: aliceblue;
  }

  .header-top-right,
  .header-top-right>div {
    display: flex;
    align-items: center;
  }

  .header-top-right img {
    width: 16px;
    height: 16px;
    margin: 0 10px;
  }
}

.nav {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #fff !important;
}

.nav-f {
  width: 100%;
  box-shadow: 0px 3px 12px 0px rgba(0, 36, 153, 0.06);
  //position: fixed;
  //top: 0;
  //left: 0;
  background: #fff;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.14);
  color: #333 !important;
}

.nav1 {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-left {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 80px;
    /* line-height: 80px; */

    .nav-left h2 {
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      line-height: 76px;
      letter-spacing: 2px;
    }

    .nav-left span {
      width: 1px;
      height: 26px;
      background: #ffffff;
      border-radius: 0px 0px 0px 0px;
      margin: 0 15px;
      opacity: 0.2;
    }
  }

  .nav-center {
    width: 600px;

    .nav-list {
      /* margin: 0 auto;
      max-width: 600px; */
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 78px;
    }

    .nav-list>div {
      width: 600px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC, sans-serif;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      text-align: center;
      border-right: solid 0.2px #d0d0d0;
    }

    .nav-list>div:last-child {
      border: none;
    }

    .nav-list--active {
      font-family: PingFangSC-Semibold, PingFang SC, sans-serif;
      font-weight: bold;
    }
  }

  .nav-line {
    margin: 0 auto;
  }

  .nav-line>div {
    /*width: 32px;*/
    height: 4px;
    background-color: #1371E7;
    /*background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);*/
    border-radius: 2px;
    transition-duration: 0.3s;
    position: relative;
    top: 0px;
  }

  .nav-center-btn {
    /* height: 40px; */
    width: 122px;
    height: 40px;
    background: #1371e7;
    border-radius: 4px 4px 4px 4px;
    border: 0;
    opacity: 1;
    font-size: 16px;
    cursor: pointer;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }


}

//-------------------
.modify {
  width: 100%;
  height: 505px;
  box-sizing: border-box;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px 0px rgba(3, 27, 78, 0.06);

  .modify-bu {
    position: relative;
    display: flex;

    img {
      width: 33.33%;
      height: 56px;
    }


    .modify-bu-zi {
      width: 100%;
      position: absolute;
      height: 56px;
      line-height: 56px;
      display: flex;
      justify-content: space-around;

      .modify-bu-zi-zou {
        display: flex;
        align-items: center;
        height: 56px;
        line-height: 56px;
        text-align: center;
        //cursor: pointer;

        span {
          display: inline-block;
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 8px;
          background: #FFFFFF;
          font-size: 16px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #667280;
          border-radius: 32px 32px 32px 32px;
          opacity: 1;
          border: 1px solid #EDEFF2;
        }
      }

      .modify-bu-zi-zou2 {
        display: flex;
        align-items: center;
        height: 56px;
        line-height: 56px;
        text-align: center;
        cursor: pointer;

        span {
          display: inline-block;
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 8px;
          font-size: 16px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #1371E7;
          background: #FFFFFF;
          border-radius: 32px 32px 32px 32px;
          opacity: 1;
          border: 1px solid #FFFFFF;
        }
      }
    }
  }

  .modify-content {
    width: 340px;
    height: 250px;
    margin: 0 auto;
    margin-top: 60px;
    //background-color: #d0d0d0;

    .modify-input {

      span {
        display: block;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        padding-bottom: 8px;
        padding-top: 16px;

      }

      .modify-ma {
        display: flex;
      }
    }
  }

  .modify-content-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 340px;
    height: 250px;
    margin: 0 auto;
    margin-top: 60px;

    .modify-content-3-pasword {
      padding: 20px 0 26px;
    }

    img {
      display: block;
      width: 64px;
      height: 64px;
    }
  }

}


//----------------

.bottom {
  width: 100%;
  height: 2.75rem;
  background: #181B20;
  font-size: 0.8125rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 1.75rem;

  .bottom-content {
    width: 61.458%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .bottom-right {
    display: flex;
    align-items: center;
  }

  .bottom-right::before {
    content: "";
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    background: url('~@/assets/home/bradge.png');
    background-size: 100% 100%;
    margin-right: 1.375rem;
  }
}

.footer {
  padding-top: 30px;
    padding-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    // height: 13rem;
    opacity: 1;
    background-image: url('~@/assets/img/pre/bg-footer.png');

  .footer-content {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 3rem 0 1.5rem 0;
    align-items: center;

    .footer-top-line-1 {
      width: 25.254%;
      height: 0;
      opacity: 0.4;
      border: 0.125rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
    }

    .footer-top-line-2 {
      width: 25.254%;
      height: 0;
      opacity: 0.4;
      border: 0.125rem solid;
      border-image: linear-gradient(191deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 2 2;
      transform: rotate(180deg);
    }

    .footer-top-line-font {
      //margin: 0 1.1%;
      font-size: 1rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.47);
      line-height: 1.25rem;
    }
  }

  .footer-detail {
    width: 61.458%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    text-align: left;
    font-size: 1rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 1.25rem;
    align-items: center;

    img {
      width: 100%;
      //height: 60px;
    }

    ul {
      list-style-type: none;
      margin-top: 1.75rem;

      li {
        margin: 0.625rem 0;
      }
    }
  }
}
</style>
